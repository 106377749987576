import { Box, Button, IconButton, Stack, Typography } from "@mui/material"
import { useTranslate } from "@pankod/refine-core"
import Icon from "components/Icon"
import RoundedButton from "components/RoundedButton"
import { GroupPackage, MIN_EVENT_HOURS, PriceTypes, WorkingTime } from "interfaces/space"
import { calculateBasePrice, calculatePackagePrice, calculatePackagePricePerPerson, checkHasServiceByPerson, formatNumberPrice, getMaxEventHours, getMaxOccupancyRange, getMinOccupancyRange, getRangePrice, getWorkingTimeMaxRange, getWorkingTimeMinRange, roundAmount } from "utils"
import DeleteIcon from '../../../../icons/delete.svg'

interface GroupPackagesPackageCardProps {
    packageData: GroupPackage
    workingTimes: WorkingTime[]
    priceType: PriceTypes
    packageIndex: number
    onEditPackage: (packageIndex: number, packageData: GroupPackage) => void
    onDeletePackage: (packageIndex: number) => void
    onDuplicatePackage: (packageIndex: number) => void
}

const GroupPackagesPackageCard: React.FC<GroupPackagesPackageCardProps> = (props) => {
    const { packageData, workingTimes, priceType, packageIndex, onEditPackage, onDeletePackage, onDuplicatePackage } = props
    const hasServiceByPerson = priceType === PriceTypes.ByPerson ? true : checkHasServiceByPerson(packageData.paidServices)
    const minRange = getWorkingTimeMinRange(workingTimes, priceType, hasServiceByPerson)
    const maxRange = getWorkingTimeMaxRange(workingTimes, priceType, hasServiceByPerson)
    const minPrice = getRangePrice(minRange, priceType)
    const maxPrice = getRangePrice(maxRange, priceType)
    const withDecimals = false
    
    const baseMinPrice = calculateBasePrice(priceType, minPrice, minRange?.occupancy?.minimum!, MIN_EVENT_HOURS)
    const minCost = calculatePackagePrice(
        minRange?.occupancy?.minimum!,
        packageData.paidServices,
        baseMinPrice,
        packageData.price
    )
    const minCostByPerson = calculatePackagePricePerPerson(
        minCost, 
        priceType === PriceTypes.ByPerson ? minRange?.occupancy?.minimum! : minRange?.occupancy?.maximum!
    )
    const baseMaxPrice = calculateBasePrice(priceType, maxPrice, maxRange?.occupancy?.maximum!, getMaxEventHours(workingTimes))
    const maxCost = calculatePackagePrice(
        maxRange?.occupancy?.maximum!,
        packageData.paidServices,
        baseMaxPrice,
        packageData.price
    )
    const maxCostByPerson = calculatePackagePricePerPerson(
        maxCost, 
        priceType === PriceTypes.ByPerson ? maxRange?.occupancy?.maximum! : maxRange?.occupancy?.minimum!
    )

    const formattedMinCost = formatNumberPrice(roundAmount(minCost), withDecimals)
    const formattedMinCostByPerson = formatNumberPrice(roundAmount(minCostByPerson), withDecimals)
    const formattedMaxCost = formatNumberPrice(roundAmount(maxCost), withDecimals)
    const formattedMaxCostByPerson = formatNumberPrice(roundAmount(maxCostByPerson), withDecimals)
    const formattedPackagePrice = formatNumberPrice(packageData.price)

    const t = useTranslate()
    const minPriceLabel = t("updateSpace.groupPackagesPriceMinimum")
    const maxPriceLabel = t("updateSpace.groupPackagesPriceMaximum")
    const priceByPersonLabel = t("updateSpace.groupPackagesPriceByPerson")
    const discountLabel = t("updateSpace.groupPackagesDiscount")
    const duplicateLabel = t("updateSpace.duplicate")
    const editLabel = t("buttons.edit")

    const onDuplicateClickHandler = () => {
        onDuplicatePackage(packageIndex)
    }

    const onEditClickHandler = () => {
        onEditPackage(packageIndex, packageData)
    }

    const onDeleteClickHandler = () => {
        onDeletePackage(packageIndex)
    }
    
    return <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        borderRadius: 2,
        boxShadow: '0px 2px 6px 2px #00000026',
        width: '100%',
        maxWidth: '519px',
        position: 'relative'
    }}>
        <Typography variant="labelLarge">{packageData.name}</Typography>
        <Typography variant="bodyMedium" sx={{mt: 1}}>{packageData.description}</Typography>
        <Stack sx={{mt: 3}}>
            <Typography variant="titleSmall">{minPriceLabel} {formattedMinCost}</Typography>
            <Typography variant="titleSmall">{priceByPersonLabel} {formattedMinCostByPerson}</Typography>
            <Typography variant="titleSmall">{maxPriceLabel} {formattedMaxCost}</Typography>
            <Typography variant="titleSmall">{priceByPersonLabel} {formattedMaxCostByPerson}</Typography>
            <Typography variant="titleSmall">{discountLabel} {formattedPackagePrice}</Typography>
        </Stack>
        <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} sx={{mt: 3}}>
            <Button 
                variant='text'
                onClick={onDuplicateClickHandler}
            >
                <Typography variant="labelLarge">{duplicateLabel}</Typography>
            </Button>
            <RoundedButton
                sx={{
                    minWidth: '146px'
                }}
                variant="outlined"
                onClick={onEditClickHandler}>
                <Typography variant='labelLarge'>{editLabel}</Typography>
            </RoundedButton>
        </Stack>
        <IconButton sx={{
            position: 'absolute',
            top: '16px',
            right: '16px'
        }} onClick={() => { onDeleteClickHandler() }}>
            <Icon
                containerWidth={24}
                containerHeight={24}
                iconWidth={19}
                iconHeight={21}
                iconSrc={DeleteIcon}
            />
        </IconButton>
    </Box>
}

export default GroupPackagesPackageCard